<template>
<div class="wrapper">
  <h1>欢迎注册</h1>
  <vxe-form ref="xForm" title-width="100" :items="items" :data="formData" v-loading="loading" :rules="rules" @submit="submitEvent" @reset="closeModal"></vxe-form>

  <vxe-modal ref="xModal" title="温馨提示" width="600" showFooter lock-scroll :esc-closable="false">
    <template #default>
      <div class="content">您已经成功申请了《正能科创物业系统》的账户服务，我们会及时与您电话沟通!</div>
    </template>
    <template #footer>
      <vxe-button status="primary" content="返回首页" @click="goBack"></vxe-button>
    </template>
  </vxe-modal>
</div>
</template>

<script>
import { mapMutations, mapActions } from "vuex";
export default {
  data() {
    return {
      loading: false,
      defaultProps: {
        value: "code",
        label: "name",
      },
      items: [{
          span: 24,
          itemRender: {
            name: "$VxeDivider",
            props: {
              title: "账户信息",
              position: "left"
            }
          },
        },
        {
          field: "account",
          title: "账户名称",
          span: 12,
          itemRender: {
            name: "$input",
            props: {
              maxlength: 24,
              placeholder: "请输入账户名称",
            },
          },
        },
        {
          field: "phone",
          title: "联系电话",
          span: 12,
          itemRender: {
            name: "$VxePhoneInput",
            props: {
              maxlength: "11",
              placeholder: "请输入联系电话",
            },
          },
        },
        {
          field: "smsCode",
          title: "验证码",
          span: 12,
          itemRender: {
            name: "$VxePhoneInput",
            props: {
              maxlength: "11",
              placeholder: "请输入短信验证码",
            },
          },
        },
        {
          span: 24,
          itemRender: {
            name: "$VxeDivider",
            props: {
              title: "基本信息",
              position: "left"
            }
          },
        },
        {
          field: "name",
          title: "企业名称",
          span: 12,
          itemRender: {
            name: "$input",
            props: {
              maxlength: 24,
              placeholder: "请输入企业名称",
            },
          },
        },
        {
          field: "shortName",
          title: "企业简称",
          span: 12,
          itemRender: {
            name: "$input",
            props: {
              maxlength: 24,
              placeholder: "请输入企业简称",
            },
          },
        },
        {
          field: "type",
          title: "类型",
          span: 12,
          itemRender: {
            name: "$select",
            optionTypeCode: "enterpriseType",
            props: {
              placeholder: "请选择企业类型",
            },
          },
        },
        {
          field: "creditCode",
          title: "信用代码",
          span: 12,
          itemRender: {
            name: "$input",
            props: {
              maxlength: "18",
              placeholder: "请输入统一社会信用代码",
            },
          },
        },
        {
          field: "legalPersonName",
          title: "企业法人",
          span: 12,
          itemRender: {
            name: "$input",
            props: {
              maxlength: 24,
              placeholder: "请输入企业法人",
            },
          },
        },
        {
          field: "legalPersonPhone",
          title: "联系电话",
          span: 12,
          itemRender: {
            name: "$VxePhoneInput",
            props: {
              maxlength: "11",
              placeholder: "请输入联系电话",
            },
          },
        },
        {
          field: 'remark',
          title: '备注',
          span: 24,
          itemRender: {
            name: '$textarea',
            props: {
              showWordCount: true,
              maxlength: "300",
              resize: "none",
              placeholder: '请输入备注内容'
            }
          }
        },
        {
          align: "center",
          span: 24,
          itemRender: {
            name: "$buttons",
            children: [{
                props: {
                  type: "submit",
                  content: "提交",
                  status: "primary",
                },
              },
              {
                props: {
                  type: "reset",
                  content: "取消",
                },
              },
            ],
          },
        },
      ],
      //表单校验规则
      rules: {
        account: [
          { required: true, message: '请输入账户名称' },
        ],
        phone: [
          { required: true, message: "请输入联系电话" },
        ],
        smsCode: [
          { required: true, message: "请输入短息验证码" },
        ],
        name: [
          { required: true, message: "请输入企业名称" },
          { min: 2, max: 30, message: "长度在 2 到 30 个字符" },
        ],
        creditCode: [
          { required: true, message: "请输入统一社会信用代码" },
          { min: 18, max: 18, message: "长度为18为的数字和字母" },
        ],
        type: [
          { required: true, message: '请选择类型' },
        ],
      },
      // 新增功能字段初始化
      formData: {
        account: "",
        phone: "",
        code: "",
        type: "2",
        name: "",
        creditCode: "",
      },
    }
  },
  created() {
    this.getDictTypeCodes();
  },
  methods: {
    ...mapMutations(["goBack"]),
    ...mapActions([
      "getSysDictService",
      "submitEventService",
    ]),

    getDictTypeCodes() {
      // 获取当前页面所需要的数据字典key
      let dictTypeCodes = [];
      this.items.forEach(item => {
        let render = item.itemRender;
        if (render && render.optionTypeCode) {
          dictTypeCodes.push(render.optionTypeCode);
        }
      })
      this.$Axios.post(`/sys/openApi/findEnumByTypeCodes`, dictTypeCodes).then(({ code, data }) => {
        if (code == 200) {
          this.items = [...this.$Tools.buildDataByDicts(this.items, data, this.defaultProps)];
        }
        this.resetEvent();
      });
    },

    // 提交数据
    submitEvent() {
      this.$refs["xForm"].validate((valid) => {
        if (!valid) {
          this.loading = true;
          this.submitEventService({
            service: "/sys/openApi/register",
            params: this.formData,
          }).then(({ code, data }) => {
            if (code == 200) {
              this.$refs.xModal.open();
              this.closeModal();
            }
            this.resetEvent();
          });
        }
      });
    },

    resetEvent() {
      this.loading = false;
    },
    closeModal() {

    },
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 1000px;
  background: #fff;
  margin: 0 auto;

  h1 {
    height: 100px;
    line-height: 100px;
    text-align: center;
  }

  .content {
    padding: 10px;
  }
}
</style>
